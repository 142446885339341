export enum FusionPromotionStates {
  APPROVED = 'Approved',
  BROKEN = 'Broken',
  CREATING_PR = 'Creating Pull Request',
  MERGED = 'Merged',
  PENDING_REVIEW = 'Pending Review',
  PR_CREATING_ERROR = 'Pull Request Creation Error',
  PR_CHECKING_ERROR = 'Pull Request Checking Error',
  REJECTED = 'Rejected',
  WITHDRAWN = 'Withdrawn',
}
export interface FusionPromotionIntegratedSystem {
  id: string;
  name: string;
  type: string;
}
export interface FusionPromotion {
  id?: string;
  sourceIntegratedSystem: FusionPromotionIntegratedSystem;
  targetIntegratedSystem: FusionPromotionIntegratedSystem;
  state: string;
  prUrl: string;
  createdBy: string;
  creationDate: string;
  approvedBy?: string;
  approvedDate?: string;
  lastModified?: string;
}
export interface FusionPromotionRequest extends FusionPromotion {
  sourceIntegratedSystemId: string;
  targetIntegratedSystemId: string;
}
export interface FusionPromotionResponse extends FusionPromotion {
  id: string;
  sourceIntegratedSystem: FusionPromotionIntegratedSystem;
  targetIntegratedSystem: FusionPromotionIntegratedSystem;
}
